import { type FC, memo, useCallback, useContext, useState } from "react"
import { ButtonToCheckout } from "@/components/Checkout/ButtonToCheckout/ButtonToCheckout"
import { ProductCategoriesTags } from "@/components/Products/parts/TagsCategories/Tags"
import { ProductsSlider } from "@/components/Products/Slider"
import {
  PLACEMENT_ARROW,
  VARIANT_ARROW,
} from "@/components/Swiper/SwiperWrapper"
import { useWindowSize } from "@/hooks/useWindowSize"
import { getBreakpointVal } from "@/styles/utils/Utils"
import { breakpoints } from "@/styles/utils/vars"
import { Button } from "@/ui/Button/Button"
import { ButtonGroup } from "@/ui/Button/StyledButton"
import { ModalContext } from "@/ui/Modal/Modal"
import { Typography } from "@/ui/Typography/Typography"
import { VIEW_PRODUCTS_LIST } from "@/utils/constants"
import { filterProductsByCategory } from "@/utils/common/helpers"
import { StyledAfterAddedToCartKit } from "./StyledAfterAddedToCartKit"
import { Product } from "../../Catalog/Product"
import { ProductType } from "../../types"
import { KitPartProducts } from "../KitPart/KitPartProducts"

export type AfterAddedToCartKitPropsType = {
  product: ProductType
  companions?: ProductType[]
  kitProducts?: ProductType[]
  analogs?: ProductType[]
  kitParents?: string[]
  onClose?: () => void
}

export const AfterAddedToCartKit: FC<AfterAddedToCartKitPropsType> = memo(
  ({ product, companions, kitProducts, kitParents, onClose }) => {
    const modalContext = useContext(ModalContext)
    const { width } = useWindowSize()
    const isLessMd =
      width !== undefined && width <= getBreakpointVal(breakpoints.md)

    const [companionsSelectedCategory, setCompanionsSelectedCategory] =
      useState<string | number | null>(null)

    const selectedCompanions = filterProductsByCategory({
      products: companions ?? [],
      category: !companionsSelectedCategory
        ? null
        : `${companionsSelectedCategory}`,
    })

    const variantArrowSlider = isLessMd
      ? VARIANT_ARROW.ANGLE
      : VARIANT_ARROW.ARROW
    const placementArrowSlider = isLessMd
      ? PLACEMENT_ARROW.VERTICAL
      : PLACEMENT_ARROW.HORIZONTAL

    const continueHandle = useCallback(() => {
      modalContext?.hide()
      if (onClose !== undefined) {
        onClose()
      }
    }, [])

    return (
      <StyledAfterAddedToCartKit>
        <Product product={product} view={VIEW_PRODUCTS_LIST} isPreview />
        <br />
        <ButtonGroup>
          <ButtonToCheckout size="medium" />
          <Button variant={"outline"} onClick={continueHandle}>
            Продолжить покупки
          </Button>
        </ButtonGroup>

        {!!kitProducts && kitProducts.length > 0 && (
          <>
            <br />
            <ProductsSlider
              products={kitProducts}
              variantArrow={variantArrowSlider}
              placementArrow={placementArrowSlider}
              isAbsoluteNav
              title={{
                content: (
                  <Typography variant={"h5"}>Состав этого комплекта</Typography>
                ),
              }}
              responsiveExtends={{
                [getBreakpointVal(breakpoints.lg)]: {
                  slidesPerView: 4,
                },
                [getBreakpointVal(breakpoints.sm)]: {
                  slidesPerView: 3,
                },
                0: {
                  slidesPerView: 1,
                },
              }}
            />
          </>
        )}

        {(companions ?? []).length > 0 && (
          <>
            <br />
            <Typography variant={"h5"}>Сопутствующие товары</Typography>
            <ProductCategoriesTags
              products={companions ?? []}
              onSelect={(uuid) => setCompanionsSelectedCategory(uuid)}
              isOutside
            />
            <ProductsSlider
              products={selectedCompanions}
              variantArrow={variantArrowSlider}
              placementArrow={placementArrowSlider}
              isAbsoluteNav
              title={{
                content: (
                  <Typography
                    variant={"h5"}
                    style={{
                      opacity: 0,
                    }}
                  >
                    Сопутствующие товары
                  </Typography>
                ),
              }}
              responsiveExtends={{
                [getBreakpointVal(breakpoints.lg)]: {
                  slidesPerView: 4,
                },
                [getBreakpointVal(breakpoints.sm)]: {
                  slidesPerView: 3,
                },
                0: {
                  slidesPerView: 1,
                },
              }}
            />
          </>
        )}

        {!!kitParents?.length && (
          <>
            <br />
            <Typography variant={"h5"}>
              Комплекты, в которые входит товар
            </Typography>
            <KitPartProducts ids={kitParents} />
          </>
        )}
      </StyledAfterAddedToCartKit>
    )
  },
)

AfterAddedToCartKit.displayName = "AfterAddedToCartKit"
