import { useQuery } from "react-query"
import { FC, memo } from "react"
import { fetchProductsList } from "@/api/productsAPI"
import {
  PLACEMENT_ARROW,
  VARIANT_ARROW,
} from "@/components/Swiper/SwiperWrapper"
import { useWindowSize } from "@/hooks/useWindowSize"
import { getBreakpointVal } from "@/styles/utils/Utils"
import { breakpoints } from "@/styles/utils/vars"
import { BaseLoader } from "@/ui/Loaders/BaseLoader/BaseLoader"
import { sortProductsWeightRule } from "@/utils/common/helpers"
import { ProductsSlider } from "../../Slider/ProductsSlider"

export type KitPartProductsPropsType = {
  ids: string[]
}

export const KitPartProducts: FC<KitPartProductsPropsType> = memo(({ ids }) => {
  const { width } = useWindowSize()
  const isLessMd =
    width !== undefined && width <= getBreakpointVal(breakpoints.md)

  const { data, isFetching } = useQuery(["kitParentsProducts", ids], () =>
    ids.length > 0
      ? fetchProductsList({
          uuids: ids.join(","),
        })
      : null,
  )

  const products = !!data
    ? sortProductsWeightRule({
        products: data,
      })
    : []

  const variantArrowSlider = isLessMd
    ? VARIANT_ARROW.ANGLE
    : VARIANT_ARROW.ARROW
  const placementArrowSlider = isLessMd
    ? PLACEMENT_ARROW.VERTICAL
    : PLACEMENT_ARROW.HORIZONTAL

  return (
    <>
      {isFetching && <BaseLoader />}
      <ProductsSlider
        products={products}
        variantArrow={variantArrowSlider}
        placementArrow={placementArrowSlider}
        isAbsoluteNav
        responsiveExtends={{
          [getBreakpointVal(breakpoints.lg)]: {
            slidesPerView: 4,
          },
          [getBreakpointVal(breakpoints.sm)]: {
            slidesPerView: 3,
          },
          0: {
            slidesPerView: 1,
          },
        }}
        disabledOnMobile
      />
    </>
  )
})

KitPartProducts.displayName = "KitPartProducts"
