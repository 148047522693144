import { getImagePath } from "@/utils/common/helpers"

type GetWatermarkImageReturnType = {
  image: string | null
  size: null | {
    width: number
    height: number
  }
}

export const WATERMARK_SRC = "/images/logo/logo_dark.svg"

export class WatermarkImageCreator {
  canvas: HTMLCanvasElement

  constructor() {
    this.canvas = document.createElement("canvas")
  }

  getWatermarkImage({
    src,
  }: {
    src: string
  }): Promise<GetWatermarkImageReturnType> {
    const original = new window.Image()
    const canvas = this.canvas

    return new Promise((resolve, reject) => {
      original.crossOrigin = "anonymous"
      original.src = getImagePath(src)
      original.onerror = reject
      original.onload = () => {
        canvas.width = original.naturalWidth
        canvas.height = original.naturalHeight

        const ctx = canvas.getContext("2d")

        if (!ctx) {
          resolve({
            image: null,
            size: null,
          })
          return
        }

        ctx.drawImage(original, 0, 0)

        const watermark = new window.Image()
        watermark.src = WATERMARK_SRC
        watermark.onerror = reject
        watermark.onload = () => {
          const w = canvas.width / 2
          const h = ((canvas.width / 2) * watermark.height) / watermark.width

          ctx.globalAlpha = 0.3
          ctx.drawImage(
            watermark,
            canvas.width / 2 - w / 2,
            canvas.height / 2 - h / 2,
            w,
            h,
          )

          resolve({
            image: canvas.toDataURL("image/png", 1),
            size: {
              width: canvas.width,
              height: canvas.height,
            },
          })
        }
      }
    })
  }
}
