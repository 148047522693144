import { useSwiperSlide } from "swiper/react"
import { FC } from "react"
import { StyledGalleryItem } from "@/components/Products/parts"
import { PanPinch } from "@/components/Products/parts/Media/Gallery/PanPinch"
import {
  ZoomImage,
  ZoomImagePropsType,
} from "@/components/Products/parts/Media/Gallery/ZoomImage"
import { useWindowSize } from "@/hooks/useWindowSize"
import { useWatermark } from "@/hooks/watermark"
import { getBreakpointVal } from "@/styles/utils/Utils"
import { breakpoints } from "@/styles/utils/vars"
import { BaseLoader } from "@/ui/Loaders/BaseLoader/BaseLoader"
import { getImagePath } from "@/utils/common/helpers"

const GalleryItemContent: FC<
  Omit<ZoomImagePropsType, "isActive"> & {
    isLessMd?: boolean
  }
> = ({ src, onChangeZoom, isLessMd }) => {
  const { image, isLoading } = useWatermark(getImagePath(src))
  const slide = useSwiperSlide()

  return (
    <StyledGalleryItem>
      {isLoading && <BaseLoader />}
      {!!image && (
        <>
          {isLessMd ? (
            <PanPinch src={image} />
          ) : (
            <ZoomImage
              src={image}
              onChangeZoom={onChangeZoom}
              isActive={slide.isActive}
            />
          )}
        </>
      )}
    </StyledGalleryItem>
  )
}

const GalleryItem: FC<Omit<ZoomImagePropsType, "isActive">> = (props) => {
  const { width } = useWindowSize()

  if (width === undefined) {
    return null
  }

  const isLessMd = width <= getBreakpointVal(breakpoints.md)

  return <GalleryItemContent {...props} isLessMd={isLessMd} />
}

export { GalleryItem }
