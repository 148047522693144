import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { FC, useState } from "react";
import { cssContent, cssWrapper } from "@/components/Products/parts/Media/Gallery/PanPinch/StyledPanPinch";
import { ZoomImagePropsType } from "@/components/Products/parts/Media/Gallery/ZoomImage";
const ZOOM_SCALE = 2;
const INITIAL_SCALE = 1;
const PanPinch: FC<Pick<ZoomImagePropsType, "src">> = ({
  src
}) => {
  const [disabled, setDisabled] = useState(true);
  return <>
      <TransformWrapper disabled={disabled} doubleClick={{
      disabled: true
    }} minScale={disabled ? INITIAL_SCALE : ZOOM_SCALE} initialScale={INITIAL_SCALE} centerOnInit centerZoomedOut wheel={{
      disabled: true
    }}>
        {({
        zoomIn,
        resetTransform
      }) => <TransformComponent wrapperClass={cssWrapper} contentClass={cssContent} wrapperProps={{
        onClick: () => {
          if (disabled) {
            setDisabled(false);
            zoomIn(ZOOM_SCALE);
          } else {
            resetTransform();
            setDisabled(true);
          }
        }
      }}>
            <img src={src} alt="" />
          </TransformComponent>}
      </TransformWrapper>
    </>;
};
export default PanPinch;