import { useEffect, useState } from "react"
import { WatermarkImageCreator } from "@/components/Products/parts/Media/Gallery/helpers"

export type UseWatermarkReturnType = {
  image: string | null
  size: null | {
    height: number
    width: number
  }
  isLoading: boolean
}

export const useWatermark = (initialSrc: string): UseWatermarkReturnType => {
  const [isLoading, setIsLoading] = useState(false)
  const [image, setImage] = useState<string | null>(null)
  const [size, setSize] = useState<null | {
    width: number
    height: number
  }>(null)

  useEffect(() => {
    const watermarkCreator = new WatermarkImageCreator()
    setIsLoading(true)
    watermarkCreator
      .getWatermarkImage({
        src: initialSrc,
      })
      .then((res) => {
        setImage(res.image)
        setSize(res.size)
      })
      .catch((error) => console.log(`Error Watermark: ${error}`))
      .finally(() => {
        setIsLoading(false)
      })
  }, [initialSrc])

  return { image, size, isLoading }
}
