import { FC, memo, MouseEvent } from "react"
import { colors } from "@/styles/utils/vars"
import { Button } from "@/ui/Button/Button"
import { ButtonVariantsPropsType } from "@/ui/Button/types"
import { GTAG_EVENTS } from "@/utils/constants"
import { gtagSend } from "@/utils/common/helpers"

export const ButtonToggleSample: FC<
  {
    sample: number
    isFetchingSample: boolean
    toggleSampleToCart: (e: MouseEvent<HTMLButtonElement>) => void
    isDisabled?: boolean
    isAllow?: boolean
  } & Pick<ButtonVariantsPropsType, "size">
> = memo(
  ({
    sample,
    isFetchingSample,
    toggleSampleToCart,
    isDisabled = false,
    size,
    isAllow,
  }) => {
    if (!isAllow) {
      return null
    }

    const isActive = sample > 0

    const toggleSampleToCartHandle = (e: MouseEvent<HTMLButtonElement>) => {
      if (!isActive) {
        gtagSend({
          event: GTAG_EVENTS.ADD_TO_CART_SAMPLE,
        })
      }
      toggleSampleToCart(e)
    }

    return (
      <Button
        as={"a"}
        href={"/"}
        variant={"link"}
        icon={isActive ? "Check" : "Tag"}
        fillIcon={isActive ? colors.green : undefined}
        isFetching={isFetchingSample}
        onClick={toggleSampleToCartHandle}
        style={{
          background: isActive ? colors.grayLight : "initial",
        }}
        disabled={isDisabled}
        size={size}
      >
        {isActive ? `Образцов в корзине ${isActive && sample}` : "Образец"}
      </Button>
    )
  },
)

ButtonToggleSample.displayName = "ButtonToggleSample"
