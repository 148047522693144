import { cx } from "@linaria/core";
import { EffectFade, FreeMode, Keyboard, Navigation, Thumbs } from "swiper";
// import required modules
import { SwiperSlide } from "swiper/react";
import { Swiper } from "swiper/types";
import { FC, memo, useCallback, useContext, useEffect, useRef, useState } from "react";
import Image from "next/image";
import { GalleryItem } from "@/components/Products/parts/Media/Gallery/GalleryItem";
import { useWindowSize } from "@/hooks/useWindowSize";
import { getBreakpointVal } from "@/styles/utils/Utils";
import { breakpoints } from "@/styles/utils/vars";
import { Button } from "@/ui/Button/Button";
import { ModalContext } from "@/ui/Modal";
import { cssSliderImage, cssSliderThumbs, StyledGallerySlider, StyledThumb } from "./StyledGallery";
import { cssNavIsDisabled, cssNavNext, cssNavPrev } from "../../../../Swiper/StyledSwiper";
import { navigationDestroy, navigationInit, NavigationInitPropsType, SwiperWrapper } from "../../../../Swiper/SwiperWrapper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "swiper/css/thumbs";
export type GallerySliderPropsType = {
  images: string[];
};
export const Gallery: FC<GallerySliderPropsType> = memo(({
  images
}) => {
  const modalContext = useContext(ModalContext);
  const [thumbsSwiper, setThumbsSwiper] = useState<null | Swiper>(null);
  const [mainSwiper, setMainSwiper] = useState<null | Swiper>(null);
  const {
    width
  } = useWindowSize();
  const [navMainIsDisabled, setNavMainIsDisabled] = useState(false);
  const prevMainRef = useRef<HTMLButtonElement>(null);
  const nextMainRef = useRef<HTMLButtonElement>(null);
  const isResponsive = width !== undefined && width <= getBreakpointVal(breakpoints.md);
  const init = useCallback(({
    swiper,
    prevEl,
    nextEl,
    cbDestroy
  }: NavigationInitPropsType & {
    cbDestroy?: () => void;
    cbInit?: () => void;
  }) => {
    if (isResponsive) {
      navigationDestroy({
        swiper
      });
      if (!!cbDestroy) {
        cbDestroy();
      }
    } else {
      navigationInit({
        swiper,
        prevEl: prevEl,
        nextEl: nextEl
      });
    }
  }, [isResponsive]);
  const [allowTouchMove, setAllowTouchMove] = useState(true);
  useEffect(() => {
    const handle = (e: any) => {
      e.preventDefault();
      return false;
    };
    document.body.addEventListener("contextmenu", handle);
    return () => {
      document.body.removeEventListener("contextmenu", handle);
    };
  }, []);
  useEffect(() => {
    return () => {
      thumbsSwiper?.destroy();
      mainSwiper?.destroy();
    };
  }, [mainSwiper, thumbsSwiper]);
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        modalContext?.hide();
      }
    };
    document.addEventListener("keydown", handleKeyDown, {
      passive: true
    });
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  if (!images.length || width === undefined) {
    return null;
  }
  return <StyledGallerySlider>
      <SwiperWrapper onInit={swiper => {
      setMainSwiper(swiper);
      init({
        swiper,
        prevEl: prevMainRef.current || undefined,
        nextEl: nextMainRef.current || undefined,
        cbDestroy: () => {
          setNavMainIsDisabled(true);
        },
        cbInit: () => {
          setNavMainIsDisabled(false);
        }
      });
    }} slidesPerView={1} spaceBetween={0} thumbs={{
      swiper: thumbsSwiper
    }} modules={[Keyboard, Navigation, EffectFade, Thumbs]} effect={"fade"} keyboard={{
      enabled: true
    }} speed={100} className={cx(navMainIsDisabled && cssNavIsDisabled, cssSliderImage)} updateOnWindowResize noSwiping={allowTouchMove}>
        {images.map((i, index) => <SwiperSlide key={index}>
            <GalleryItem src={i} onChangeZoom={zoomed => setAllowTouchMove(!zoomed)} />
          </SwiperSlide>)}
        {!navMainIsDisabled && <>
            <Button variant={"arrow"} icon={"AngleLeft"} className={cssNavPrev} ref={prevMainRef} />
            <Button variant={"arrow"} icon={"AngleRight"} className={cssNavNext} ref={nextMainRef} />
          </>}
      </SwiperWrapper>

      <SwiperWrapper onInit={swiper => {
      setThumbsSwiper(swiper);
    }} spaceBetween={10} modules={[Keyboard, Navigation, FreeMode, Thumbs]} slidesPerView={"auto"} breakpoints={{
      0: {
        slidesPerView: "auto"
      }
    }} keyboard freeMode watchSlidesProgress className={cx(cssSliderThumbs)} updateOnWindowResize centerInsufficientSlides direction={isResponsive ? "horizontal" : "vertical"}>
        {images.map((i, index) => <SwiperSlide key={index}>
            <StyledThumb>
              <Image src={i} loading={"lazy"} layout={"fixed"} height={80} width={80} alt={""} objectFit={"contain"} />
            </StyledThumb>
          </SwiperSlide>)}
      </SwiperWrapper>
    </StyledGallerySlider>;
});
Gallery.displayName = "Gallery";