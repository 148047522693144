import { FC, memo } from "react"
import dynamic, { DynamicOptions } from "next/dynamic"
import { getProductPath } from "@/hooks/product/helpers"
import { Trigger } from "@/styles/utils/Utils"
import { Modal } from "@/ui/Modal/Modal"
import { Typography } from "@/ui/Typography/Typography"
import type { KitPartProductsPropsType } from "./KitPartProducts"

const KitPartProducts = dynamic(
  (() =>
    import("./KitPartProducts").then(
      (mod) => mod.KitPartProducts,
    )) as DynamicOptions<KitPartProductsPropsType>,
  {
    ssr: false,
  },
)

export type KitPartPropsType = {
  kitParents: string[]
}

export const KitPart: FC<KitPartPropsType> = memo(({ kitParents }) => {
  const lengthKitParents = kitParents.length
  if (!lengthKitParents) {
    return null
  }

  if (lengthKitParents <= 1) {
    return (
      <Typography style={{ margin: 0 }}>
        <a href={getProductPath(kitParents[0])}>Является частью комплекта</a>
      </Typography>
    )
  }

  return (
    <Modal
      variant={"rounded-100"}
      closeMode={"destroy"}
      title={"Комплекты, в которые входит товар"}
      disclosure={
        <Typography variant={"p14"}>
          <Trigger>Является частью комплекта</Trigger>
        </Typography>
      }
    >
      <KitPartProducts ids={kitParents} />
    </Modal>
  )
})

KitPart.displayName = "KitPart"
