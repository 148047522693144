import { FC, memo, useMemo, useState } from "react"
import Property from "./Property"
import { Controls, Fake, StyledProperties } from "./StyledProperties"
import { PropertiesType } from "../../../../types/types"
import { Button } from "../../../ui/Button/Button"

export const COUNT_VISIBLE = 4

export type CharactersPropsType = {
  items: PropertiesType | null
}

export const Properties: FC<CharactersPropsType> = memo(
  ({ items, children }) => {
    const [isFull, setIsFull] = useState(false)

    const charactersShowedToggle = () => {
      setIsFull((prevIsFull) => !prevIsFull)
    }

    const propertiesElements = useMemo(
      () =>
        items !== null
          ? items.map(({ name, value }, index) => (
              <Property key={index} title={name} value={value} />
            ))
          : null,
      [items],
    )

    return (
      <StyledProperties data-is-full={isFull}>
        {children}
        {propertiesElements}
        <Controls>
          <Fake />
          {propertiesElements && propertiesElements.length > COUNT_VISIBLE && (
            <Button
              variant={"small"}
              isHiddenBg
              onClick={charactersShowedToggle}
            >
              {!isFull ? "Показать еще" : "Скрыть"}
            </Button>
          )}
        </Controls>
      </StyledProperties>
    )
  },
)

Properties.displayName = "Properties"
